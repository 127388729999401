import React from 'react';
import Header from 'screens/TournamentDetails/Register/Header';
import Button from 'components/Button';
import classNames from 'styles/utils/classNames';
import Stripe from './Stripe';

interface Props {
  onConnectBankAccount?: () => void;
  heading: string;
  description: string;
  showButton: boolean;
  action?: React.ReactNode;
  showBack?: boolean;
  showBackAction?: () => void;
  inCard?: boolean;
}

export default function BankAccountPrompt({
  onConnectBankAccount,
  heading,
  description,
  action,
  showButton = true,
  showBack = false,
  showBackAction,
  inCard = false,
}: Props) {
  return (
    <div
      className={classNames(
        'flex flex-col rounded-xl border border-color-border-input-lightmode bg-color-bg-lightmode-primary p-6 shadow-lightmode-primary dark:border-color-border-input-darkmode dark:bg-color-bg-darkmode-primary',
        inCard && 'border-0 pt-0 shadow-none',
      )}
    >
      <Header title={heading} showBack={showBack} showBackAction={showBackAction} />
      <p className="typography-product-body mt-4 text-left text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        {description}
      </p>
      {action}
      {showButton && (
        <div className="my-ds-xl border-y-2 border-color-border-input-lightmode py-ds-xl text-center dark:border-color-border-input-darkmode">
          <div className="flex justify-center">
            <Stripe />
          </div>
          <p className="typography-product-body mt-ds-sm text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            All payments are managed by Stripe.
          </p>
        </div>
      )}
      {showButton && (
        <Button variant="brand" size="lg" className="mx-auto" onClick={onConnectBankAccount}>
          Complete payment setup
        </Button>
      )}
    </div>
  );
}
