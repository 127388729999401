import {
  GetCoachBookingPageQuery as ClientGetCoachBookingPageQuery,
  CoachReviewTypesEnum,
  FileCategoryEnum,
  FileFormatEnum,
  GetCoachPremiumContentForAnonymousUserQuery,
  GetCoachPremiumContentQuery,
  GetCoachReviewsByCoachIdQuery,
  GetCoachUpcomingClinicLessonsByIdQuery,
  GetUserCreditCardsQuery,
} from 'types/generated/client';
import { type GetCoachBookingPageQuery, GetCoachStatusAndSlotsQuery } from 'types/generated/server';
import { LocationType, VenueAddress, VideoAnalysisSkillsTypeEnum } from 'utils/shared/coachBuilder';
import { LessonSchedulerProps } from './components/TabSwitch';

export const PLAY_SESSIONS_ID = 'play-sessions';

export enum Steps {
  CreateAccount = 'CREATE_ACCOUNT',
  Login = 'LOGIN',
  SelectTime = 'SELECT_TIME',
  PaymentForm = 'PAYMENT_FORM',
  ResultForm = 'RESULT_FORM',
  // Optional for Remote Coaching
  VideoAnalysis = 'VIDEO_ANALYSIS',
  VideoUploader = 'VIDEO_UPLOADER',
  VideoSuccessFailure = 'VIDEO_SUCCESS_FAILURE',
  // for premium content
  Subscription = 'SUBSCRIPTION',
  SubscriptionPay = 'SUBSCRIPTION_PAY',
  SubscriptionTime = 'SUBSCRIPTION_TIME',
}

export interface PageProps {
  coach: GetCoachBookingPageQuery['userProfiles'][0];
  coachStarterProfile: GetCoachBookingPageQuery['coachStarterProfilesByPk'];
  usernameOrId: string;
  jsonLd?: string;
}

export type Coach =
  | Omit<Exclude<GetCoachBookingPageQuery['userProfiles'][0], null | undefined>, '__typename'>
  | ClientGetCoachBookingPageQuery['userProfiles'][0];

interface Faq {
  question: string;
  answer: string;
}

export interface ContentProps {
  coach: Coach;
  coachStarterProfile: GetCoachBookingPageQuery['coachStarterProfilesByPk'];
  faqs?: Faq[];
  coachAvailability?: ClientGetCoachBookingPageQuery['userProfiles'][0]['coachAvailability'];
  venues: VenueAddress[];
  handleBookVenue: (venue: VenueAddress) => void;
  isFollowingCoach: boolean;
  handleCoachFollowingBtnClick: (follow: boolean) => void;
  hideToggleFollowBtn: boolean;
  handleGetStarted: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onSubscribe: () => void;
  onEditPremiumPrice: () => void;
  onUploadContent: () => void;
  premiumCoachContent:
    | GetCoachPremiumContentQuery
    | GetCoachPremiumContentForAnonymousUserQuery
    | null;
  isPlayerSubscribedToPremium: boolean;
  premiumContentLoading: boolean;
  isCoachOfPage: boolean;
}

export type CoachProps = { coach: Coach };

export type CoachPropsForForm = CoachProps & {
  isReview?: boolean;
};

export interface RegisterProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  // coach: Coach;
  // registrations?: GetUserCoachRegistrationQuery;
  // user: ReturnType<typeof useGetCurrentUser>['user'];
  handleNext: () => void;
}

export interface CurrentDateTimeState {
  date: Date;
  startTime: string;
  endTime: string;
}

export interface CurrentVenueCourtState {
  id: string;
  title: string;
  address: string;
  locationType: LocationType;
}

export interface PaymentFormProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  currentDateTime?: CurrentDateTimeState;
  coach: Coach;
  setBookedLessonId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedCourtVenue?: CurrentVenueCourtState;
  allowBack?: boolean;
  onPaymentSuccess?: () => void;
  isRemoteCoaching?: boolean;
  remoteCoachData?: VideoAnalysisSubmissionType;
  disableSubmit?: boolean;
  handleClose?: () => void;
}

export interface ResultFormProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  currentDateTime?: CurrentDateTimeState;
  coach: Coach;
  bookedLessonId: string | null;
  selectedCourtVenue?: CurrentVenueCourtState;
  handleBookAnotherLesson?: () => void;
  isRemoteCoaching?: boolean;
}

export const EXIT_TEXT = 'No thanks, take me to the coaches';

export const ENABLE_BIRTHDAY_REQUIREMENT = false;

export interface CheckoutFormProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  currentDateTime?: CurrentDateTimeState;
  coach: any;
  onSubmit: () => void;
  phoneExists?: string;
  setBookedLessonId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedCourtVenue?: CurrentVenueCourtState;
  allowBack?: boolean;
  isRemoteCoaching?: boolean;
  remoteCoachData?: VideoAnalysisSubmissionType;
  disableSubmit?: boolean;
  creditCards?: GetUserCreditCardsQuery['userCreditCards'];
  isLoadingCreditCards?: boolean;
  hasSetupIntent?: boolean;
  fetchSetupIntent?: any;
  isStripeKeyFetching?: boolean;
  handleClose?: () => void;
}

export type Lessons = GetCoachStatusAndSlotsQuery['users'][0]['coachLessons'];

// Video Uploader types
export interface VideoAnalysisSubmissionProps {
  coach?: Coach;
  steps?: Steps;
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  setSubmissionData?: (data: VideoAnalysisSubmissionType) => void;
  handleClose?: () => void;
}

export interface ManageFollowCoachModalProps {
  isOpen: boolean;
  handleClose: () => void;
  isFollowPrompt: boolean;
  loading: boolean;
  handleChangeCoachFollowing: (follow: boolean) => void;
}

export interface videoFileType {
  fileCategory: FileCategoryEnum;
  fileName: string;
  fileType: string;
  host: string;
  originalFileName: string;
  provider: string;
  providerUrl: string;
  url: string;
  fileFormat: FileFormatEnum;
  providerId: string;
  userId: string;
}

export interface VideoAnalysisSubmissionType {
  videoAnalysisFiles?: videoFileType[];
  details?: string | undefined;
  skills?: string[];
}

export interface SkillTagProps {
  skill: SkillStructure;
  isActive?: boolean;
  onClick: (skill: SkillStructure) => void;
}

export interface SkillStructure {
  label: string;
  value: string;
}

export interface SkillTagsProps {
  skills: SkillStructure[];
  activeSkills: SkillStructure[];
  onSkillClick: (skill: SkillStructure) => void;
}

export interface VideoAnalysisProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
}

export interface ClinicSectionProps {
  loading: boolean;
  coachUpcomingClinicLessonsData: GetCoachUpcomingClinicLessonsByIdQuery | null;
  handleGetUpcomingLessonsById: (currentOffset: number) => void;
}

export interface ClinicCardProps {
  groupLesson: GetCoachUpcomingClinicLessonsByIdQuery['lessons'][0];
}

export interface SubscriptionModalProps {
  isOpen: boolean;
  handleClose: () => void;
  subscriptionPrice: number;
  isOwner: boolean;
  coachId: string;
  steps: Steps;
  playerSubscribedUntil: Date | null;
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  onSubscriptionSuccess: () => void;
  coachName: string;
}

export interface PremiumSectionProps {
  onSubscribe: () => void;
  isOwner: boolean;
  onEditPremiumPrice: () => void;
  onUploadContent: () => void;
  coachId: string;
  premiumCoachContent:
    | GetCoachPremiumContentQuery
    | GetCoachPremiumContentForAnonymousUserQuery
    | null;
  isPlayerSubscribedToPremium: boolean;
  premiumContentLoading: boolean;
}

export type ReviewType = GetCoachReviewsByCoachIdQuery['reviews'][0];

export interface ReviewsOverviewModalProps {
  open: boolean;
  handleClose: () => void;
  reviews: ReviewType[];
}

export interface TabsProps {
  activeTab: ReviewTabsEnum;
  setActiveTab: React.Dispatch<React.SetStateAction<ReviewTabsEnum>>;
}

export enum ReviewTabsEnum {
  OnCourt = CoachReviewTypesEnum.Court,
  Remote = CoachReviewTypesEnum.Remote,
  All = 'all',
}

export type SortCriterionType = {
  label: string;
  value: 'high' | 'low' | 'most';
};
